import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { obtenirImage, obtenirImages, obtenirPage } from "../js/client-es/utils"
import LayoutFR from "../composants/layout/fr/LayoutFR"
import Image from "../composants/image/Image"

import fragmentsImages from "../js/client-es/fragments/fragments-images"
import fragmentsPages from "../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageContact: allPagesCompilationYaml(filter: { code: { eq: "contact" } }) {
      ...FragmentPageYaml
    }
    images: allImagesCompilationYaml(filter: { code: { in: ["stargate"] } }) {
      ...FragmentImagePortraitPetit
    }
  }
`

export default function Contact() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageContact")
  const images = obtenirImages(resultatsRequete, "images")

  return (
    <LayoutFR page={page}>
      <p>
        Pour toute demande (questions sur mes travaux, tirages, formation…),
        envoyez-moi un message en remplissant le formulaire ci-dessous. Merci
        beaucoup.
      </p>
      <div className="conteneur-cote-a-cote">
        <div className="conteneur-cote-a-cote-texte">
          <form
            id="fs-frm"
            name="formulaire-contact"
            acceptCharset="utf-8"
            action="https://formspree.io/f/mlearewv"
            method="post"
          >
            <fieldset id="fs-frm-inputs">
              <label htmlFor="full-name">Votre nom et votre prénom :</label>
              <input type="text" name="name" id="full-name" required={true} />
              <label htmlFor="email-address">Votre email :</label>
              <input
                type="email"
                name="_replyto"
                id="email-address"
                required={true}
              />
              <label htmlFor="message">Votre message :</label>
              <textarea
                rows="15"
                name="message"
                id="message"
                required={true}
              ></textarea>
              <input
                type="hidden"
                name="_subject"
                id="email-subject"
                value="Contact Form Submission"
              />
            </fieldset>
            <input type="submit" value="Envoyer" id="bouton-envoyer" />
            <label htmlFor="bouton-envoyer">
              (un dispositif anti-spam va s’afficher, merci de suivre les
              instructions !)
            </label>
          </form>
        </div>
        <Image
          image={obtenirImage(images, "stargate")}
          afficherLegende={false}
          langue={page.langue}
          afficherOmbre={true}
        />
      </div>
    </LayoutFR>
  )
}
